import React, { Component } from "react";
import LoginComponent from "./loginComponent";
import Grid from '@material-ui/core/Grid';
import AppUpdate from "../AppUpdate";
import PureJSComponentFactory from "./Pages/Factory/pureJSComponentFactory";
import { offlineLayoutInfo } from "../General/globals";
import PopupComponent from "./popupComponent";
 

class unAuthorizedComponent extends Component {
    constructor(props) {
        super(props);
      
        this.pageFooter = new PureJSComponentFactory().scaffoldComponent(offlineLayoutInfo?.mainfooter);
   
        // localStorage.getItem("")
        //ExecuteLayoutEventMethods(whenAppInit); //AppInit events from Initjson

    }
    render() {
         return (
            <>
            <div className="login-container" >
                <div className="update-container">
                    <AppUpdate />
                </div>
                <Grid container className="login-grid">
                    <Grid item lg={4} sm={2}></Grid>
                    <Grid item lg={4} sm={8}>
                        <LoginComponent />
                    </Grid>
                    <Grid item lg={4} sm={2}></Grid>
                </Grid>
                
                   {this.pageFooter}
                
            </div>
                <PopupComponent  />
            </> 
        );

    }

}

export default unAuthorizedComponent; 