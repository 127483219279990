import React, { Component } from "react";
import AppBarComponent from "./Parts/appBarComponent";
import StorageService from "../Services/storageService";
import { userDetailsStorageKey, userMenuStorageKey, whenAppLoad } from "../General/globals";
import GenericPageComponent from "./Pages/genericPageComponent";
import { Grid } from "@material-ui/core";
import { ExecuteLayoutEventMethods, SetCreateDataSet } from "../General/commonFunctions";
import PageHeaderSection from './Parts/pageHeaderSection';
import PageFooterSection from "./Parts/pageFooterSection";
import PopupComponent from "./popupComponent";
 

class AuthorizedComponent extends Component {
    constructor(props) {
        super(props);
        let userInfo = new StorageService().getJsonValue(userDetailsStorageKey);
        let _userMenu = new StorageService().getJsonValue(userMenuStorageKey);
        _userMenu =
            _userMenu && _userMenu?.map &&
            _userMenu?.map(t => {
                let actionObj = JSON.parse(t.action);
                t.action = actionObj;
                return t;
            });

        this.state = {
            // toolbar: this.props.pageInfo?.toolbar,
            // pageLayout: this.props.pageInfo?.layout,
            userMenu: _userMenu
        };
        if (!userInfo || !_userMenu) {

            console.log("Tampered UserInfo! Dispatching Logout...!");
            ExecuteLayoutEventMethods([{ "exec": "logout" }]);
            return;
        }


        // store.dispatch(storeUser(userInfo)); //Redux
        SetCreateDataSet({ "dset": "_userinfo" }, userInfo); //Redux
        SetCreateDataSet({ "dset": "_usermenu" }, _userMenu); //Redux
        ExecuteLayoutEventMethods(whenAppLoad, null, null, true); //Appload from Initjson

    }



    async componentDidMount() {
        this.handleRouting();
        // ownStore.dispatch(hideAppBusy());

    }

    async handleRouting() {

        let docNoFromRoute = this.props?.match?.params["permalink"];
        let pageToShow = this.state.userMenu?.filter(t => docNoFromRoute === t.permalink);
        if (pageToShow?.length <= 0) {
            pageToShow = this.state.userMenu?.filter(t => t.homepage && t.homepage.toLowerCase() === "true");
        }
        if (pageToShow?.length > 0) {
            // window.location.hash = "#" + pageToShow[0]?.permalink;
            await ExecuteLayoutEventMethods([{
                "exec" : "setdataset", 
                "args" : {
                    "dset" : "pagemenuinfo",
                    "data" :pageToShow[0]
                }
            }], null, null, true); 
            await ExecuteLayoutEventMethods(pageToShow[0]?.action, null, null, true);
        }
    }

    render() {
        return (
            // <ThemeProvider theme={this.theme} >
            <>
                <div className="main-wrap" >
                    {this.props.pageInfo &&  
                        <AppBarComponent {...this.props.pageInfo?.appbar} userMenu={this.state.userMenu} /*executeMenuRouting={this.executeMenuRouting.bind(this)} />*/ ></AppBarComponent>
                    }
                    <div className="page-wrap" style={this.props.pageInfo?.style}>

                        <PageHeaderSection pageHeader={this.props.pageInfo?.pageheader} />

                        <Grid container className="page-container" >
                            <GenericPageComponent pageInfo={this.props.pageInfo} />
                        </Grid>
                    </div>
                    <PageFooterSection pageFooter={this.props.pageInfo?.pagefooter} userMenu={this.state.userMenu} />

                </div>
                <PopupComponent  />
            </>
            // </ThemeProvider>
        );
    }

}

export default AuthorizedComponent; 